import React from 'react';

const ComingSoon = () => (
    <section className="section section--gradient">
        <div className="container">
            <div className="content">
                <h1 className="title">Coming Soon!</h1>
            </div>
        </div>
    </section>
);

export default ComingSoon;
